<template>
  <div class="main">
    <div class="project_list">
      <!-- 选择器 -->
      <div class="select_box">
        <!-- 运营商 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-supAdmin
          v-model="id_operator"
          placeholder="请选择运营商"
        >
          <el-option
            v-for="ite in operatorList"
            :key="ite.id"
            :label="ite.name_operator"
            :value="ite.id_operator"
          ></el-option>
        </el-select>

        <!-- 项目名称 -->
        <el-input
          class="ipt_box mr12 mt12"
          v-model="name_project"
          placeholder="请输入项目名称"
        ></el-input>

        <!-- 联系人 -->
        <el-input
          class="ipt_box mr12 mt12"
          v-model="name_chief"
          placeholder="请输入联系人"
        ></el-input>

        <!-- 联系方式 -->
        <el-input
          class="ipt_box mr12 mt12"
          v-model="tel"
          placeholder="请输入联系方式"
        ></el-input>

        <el-date-picker
          class="time_box mr12 mt12"
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <div class="mr12 mt12">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button size="small" type="primary" plain @click="reset">重置</el-button>
        </div>
      </div>

      <!-- 表格 -->
      <div class="table_box">
        <div class="add_project">
          <i class="el-icon-plus" color="#2a47b0" @click="addProject"></i>
          <span @click="addProject">添加项目</span>
        </div>
        <el-table
          :data="projectList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb',
            height: '40px',
          }"
          :stripe="true"
          size="medium"
        >
          <el-table-column type="expand" label="系统列表" width="120">
            <template v-slot="scope">
              <!-- 表格 -->
              <div class="inner_table">
                <el-table
                  :data="scope.row.deviceList"
                  style="width: 100%"
                  border
                  :header-cell-style="{
                    background: '#fafafb',
                    height: '40px',
                  }"
                  :stripe="true"
                  size="small"
                  :span-method="spanMerge"
                >
                  <el-table-column prop="name" label="系统名称"></el-table-column>

                  <el-table-column label="管理员">
                    <template v-slot="scope">
                      <span>{{ scope.row.real_name || "--" }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="联系方式">
                    <template v-slot="scope">
                      <span>{{ scope.row.tel || "--" }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="创建时间">
                    <template v-slot="scope">
                      <span>{{ scope.row.time_create || "--" }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name_project" label="项目名称"></el-table-column>
          <el-table-column prop="name_chief" label="联系人"></el-table-column>
          <el-table-column prop="tel" label="联系方式"></el-table-column>
          <el-table-column prop="addr" label="项目地址"></el-table-column>
          <el-table-column prop="time_create" label="创建时间"></el-table-column>
          <el-table-column label="更新时间">
            <template v-slot="scope">
              <span>
                {{ scope.row.time_update || "-" }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="160">
            <template v-slot="scope">
              <span class="btn_box cursor" @click="editProject(scope.row)">
                <i class="el-icon-edit"></i>
                <span class="edit_table">修改</span>
              </span>

              <span class="btn_box cursor" @click="delProject(scope.row)">
                <i class="el-icon-delete"></i>
                <span class="edit_table">删除</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页-->
      <div class="pagination_box">
        <el-pagination
          @size-change="sizeChange"
          @current-change="CurrentChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增 修改项目 -->
    <ChangeProject ref="changeProjectRef" @refresh="getProjectList" />

    <!-- 批量初始化 -->
    <ResetSomeDevice ref="resetSomeDeviceRef" @refresh="getProjectList" />
  </div>
</template>

<script>
import { GetProjectList, DelProject, GetOperatorList2 } from "@/api";
import ChangeProject from "./dialogs/change_project.vue";
import ResetSomeDevice from "./dialogs/reset_some_device.vue";

export default {
  name: "project_list",
  components: { ChangeProject, ResetSomeDevice },
  data() {
    return {
      // 角色权限
      role: "",
      total: 0,
      page_id: 0,
      page_num: 20,
      // 运营商列表
      operatorList: [],
      // 运营商id
      id_operator: "",
      // 项目名称
      name_project: "",
      // 联系人
      name_chief: "",
      // 联系方式
      tel: "",
      // 创建日期
      date: ["", ""],
      // 项目列表
      projectList: [],
    };
  },
  methods: {
    // 超级管理员进来获取运营商列表
    async getOperatorList() {
      const { ret, data, msg } = await GetOperatorList2();
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.operatorList = data.data;
    },

    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s;
      this.page_id = 0;
      this.getProjectList();
    },

    // 页码变化
    CurrentChange(p) {
      this.page_id = p;
      this.getProjectList();
    },

    // 查询
    search() {
      this.page_id = 0;
      this.getProjectList();
    },

    // 重置
    reset() {
      if (this.role === 100) {
        this.id_operator = "";
      }
      this.name_project = this.name_chief = this.tel = "";
      this.date = null;
      this.page_id = 0;
      this.getProjectList();
    },

    // 获取参数
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num,
      };
      if (this.role === 100 && this.id_operator) {
        params.id_operator = this.id_operator;
      }
      if (this.name_project.trim()) {
        params.name_project = this.name_project.trim();
      }
      if (this.name_chief.trim()) {
        params.name_chief = this.name_chief.trim();
      }
      if (this.tel.trim()) {
        params.tel = this.tel.trim();
      }
      if (this.date && this.date[0] && this.date[1]) {
        params.time_start = this.date[0] + " 00:00:00";
        params.time_end = this.date[1] + " 23:59:59";
      }
      return params;
    },

    // 获取项目列表
    async getProjectList() {
      const params = this.getParams();
      const { ret, data, msg } = await GetProjectList(params);
      if (ret !== 0) {
        return this.$message.error(msg || "获取项目列表失败!");
      }
      this.total = data.cnt_all;
      data.data.map((i) => {
        const temp = [];
        i.device_list.map((j) => {
          if (j.admin.length) {
            j.admin.map((k) => {
              temp.push({
                name: j.name,
                real_name: k.real_name,
                tel: k.tel,
                time_create: k.time_create,
              });
            });
          } else {
            temp.push({
              name: j.name,
              real_name: "--",
              tel: "--",
              time_create: "--",
            });
          }
        });
        // 整合 纵列需要合并的次数
        let startIndex = 0;
        let count = 0;
        temp.map((t, it) => {
          if (it === 0) {
            // 第一项
            t.rowspan = 1;
            t.colspan = 1;
            startIndex = 0;
            count = 1;
          } else {
            // 其他项 和上一项的名字一样
            if (t.name === temp[it - 1].name) {
              // 同步起始项
              count++;
              temp[startIndex].colspan = count;
              // 更新当前项
              t.rowspan = 0;
              t.colspan = 0;
            } else {
              // 和上一项的名字不一样
              startIndex = it;
              count = 1;
              t.rowspan = 1;
              t.colspan = 1;
            }
          }
        });
        i.deviceList = temp;
      });
      this.projectList = data.data;
    },

    // 添加项目
    addProject() {
      this.$refs.changeProjectRef.add();
    },

    // 编辑项目
    editProject(row) {
      this.$refs.changeProjectRef.edit(row);
    },

    // 删除项目
    async delProject(row) {
      // 判断是否有系统
      if (row.device_list.length) {
        // 批量初始化操作
        this.$refs.resetSomeDeviceRef.open(row);
      } else {
        const result = await this.$confirm(
          "是否确定删除该项目? (删除后不可恢复)",
          "删除提醒",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch(() => "cancel");
        if (result === "cancel") return;
        const { ret, data, msg } = await DelProject({ id_project: row.id });
        if (ret !== 0) {
          return this.$message.error(msg);
        }
        this.$message.success("删除成功");
        this.getProjectList();
      }
    },

    // 内嵌表格实现列合并
    spanMerge(row, column, rowIndex, columnIndex) {
      if (row.columnIndex === 0) {
        return [row.row.colspan, row.row.rowspan];
      }
    },
  },

  created() {
    this.role = this.$store.getters.role;
    if (this.role === 100) {
      this.getOperatorList();
    }
    this.getProjectList();
  },
};
</script>

<style lang="scss" scoped>
.project_list {
  width: 100%;
  min-height: 100%;
  background: #fff;
  border-radius: $radius;
  padding: $padding;
}

.table_box {
  padding: 0 12px;
  margin-top: 12px;

  .add_project {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $theme;
    cursor: pointer;

    i {
      font-size: 14px;
    }

    span {
      margin-left: 4px;
      font-size: 14px;
    }
  }

  .btn_box {
    margin: 0 8px;

    i {
      color: $theme;
      font-size: 14px;
    }
  }

  .inner_edit_table {
    font-size: 14px;
    color: #2a47b0;
    cursor: pointer;
    margin: 0 8px;
  }
}
</style>
